import React, { Component, useEffect } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import CircleType from 'circletype';
import Faq from '../../components/faq/Faq';
import bird1 from '../../assets/images/birds/bird1.png';
import bird2 from '../../assets/images/birds/bird2.png';
import eye from '../../assets/images/icons/eye.svg';
import compass from '../../assets/images/icons/compass.svg';
import food from '../../assets/images/icons/food.svg';
import mouth from '../../assets/images/icons/mouth.svg';
import sound from '../../assets/images/icons/sound.svg';
import Experience from '../../components/experiences/experience';
// experiences
import picnic1 from '../../assets/images/experiences/picnic1.jpg';

// transitionimage
import transitionimage from '../../assets/images/transition.jpg';
// travelines
import Travelline from '../../components/travellines/Travelline1';

// rotate text
import experienceSpinner from '../../assets/images/experiences/experience_spinner.svg';

const Page = styled.div`
  margin-top: 7.5vw;

  .siteWrapper {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-column: 1 / span 24;
    @media (min-width: 960px) {
      grid-column: 5 / span 16;
    }
    .experiences-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
      }
      grid-template-rows: masonry;
      grid-gap: 30px;
    }
  }
  .img {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  .text {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  header {
    grid-column: 2 / span 22;
    @media (min-width: 1100px) {
      grid-column: 5 / span 16;
    }

    padding: 3.5vw 0 0 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .back {
      margin-bottom: 20px;
      display: block;
      font-size: 1em;
    }
    .intro-text {
      grid-column: 1 / span 4;
      @media (min-width: 768px) {
        grid-column: 1 / span 3;
      }
      .title {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
      }
      .sub-heading {
        font-size: 1.2rem;
      }
      h1 {
        font-size: 3em;
        padding-bottom: 10px;
      }
      margin-bottom: 40px;
    }
    .top-right {
      grid-column: 6 / span 1;
      @media (min-width: 768px) {
        grid-column: 4 / span 6;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .circle-rotate {
        position: absolute;
        font-size: 0.7em;
        color: var(--olive);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        #circle-text {
          width: 130px;
          height: 130px;
          animation: spin 60s infinite linear;
          max-width: none;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      img {
        width: 60px;
      }
    }
    .right {
      grid-column: 1 / span 6;
      display: flex;
      flex-direction: row;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      .img1 {
        flex: 1;

        img {
          display: block;
        }
      }
    }
  }

  // min-height: 100vh;
`;

const Bird2 = styled.div`
  position: absolute;
  left: -70px;
  top: 0%;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;

const Bird3 = styled.div`
  position: absolute;
  right: -70px;
  top: 400px;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;
const ExperiencesStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: masonry;
  grid-gap: 0;
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Experiences() {
  useEffect(() => {});
  return (
    <Page>
      <Bird2>
        <img src={bird2} alt="" />
      </Bird2>
      <Bird3>
        <img src={bird1} alt="" />
      </Bird3>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <div className="intro-text">
              <AniLink
                className="link back b-i"
                to="/experiences"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Back to Experiences
              </AniLink>
              <h1>The Easy Picnic</h1>
              <p className="sub-heading b-i">
                Find a cosy spot, a refreshing cocktail, some people you love
                and let the warm rays of the day soak into your soul.
              </p>
            </div>
            <div className="top-right">
              <div className="circle-rotate">
                <img
                  id="circle-text"
                  className="sb"
                  src={experienceSpinner}
                  alt=""
                />
              </div>
              <img src={sound} alt="" />
            </div>
            <div className="right">
              <div className="img1">
                {' '}
                <img src={picnic1} alt="" />
              </div>
            </div>
          </header>

          <div className="text">
            <p>
              Find a cosy spot, some people you love and let the warm rays of
              the day soak into your soul. The sounds of vinyl will float over
              the lawns of Nahargarh through the weekend, taking you on a
              journey of rare, hidden and organic sounds from different corners
              of the globe.
            </p>
            <p>
              Culture curator and boxout.fm co-founder DJ MoCity will be taking
              over on Friday with a special edition of City Goes Wax. Popular
              YouTube channel My Analog Journal, that explores rare grooves
              around the world on vinyl, will take over on Saturday and Sunday
              with My Analog Journal founder and London-based music producer, DJ
              and filmmaker Zag Erlat, as well as Indian selector Digging in
              India, and DJ MoCity on back up duties.
            </p>
            <p className="i">
              Performances by:
              <br />
              <AniLink
                className="link back b"
                to="/artists/zag-erlat"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Zag Erlat - My Analog Journal
              </AniLink>
              <br />
              <AniLink
                className="link back b"
                to="/artists/dj-mocity"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Dj MoCity - City Goes Wax
              </AniLink>
              <br />
              <AniLink
                className="link back b"
                to="/artists/digging-in-india"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Digging In India
              </AniLink>
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
}
